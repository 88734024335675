
<template>
    <el-empty description="好像迷路了，点击“回家”按钮，小鸽送你回家 (゜-゜)つロ">
        <el-button type="primary" @click="gotoLogin">回家</el-button>
    </el-empty>
</template>
<script>
export default {
    methods: {
        gotoLogin(){
          //console.log("gotoLogin");
          //通过push进行跳转
          window.sessionStorage.clear();
          this.$router.push('/login')
        }
    }
}
</script>
<style lang="less" scoped>
</style>